import React from 'react'
import styled from 'styled-components';

const EventContainer = styled.li`
    margin: 0;
    padding: 0;
    position: relative;
`;

const Icon = styled.label`  
    background-color: #E3E1Ee;
    outline: 10px solid white;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    border-radius: 0.5em;    
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    width: 1em;
    height: 1em;
    @media (max-width: 1199px) {
      left: 2em;
    }
    @media (max-width: 990px) {
      left: 1em;
    }
    @media (max-width: 575px) {
      left: 0em;
    }    
`;

const IconActive = styled.label`  
    background-color: #b3b9e7;
    outline: 10px solid white ;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    border-radius: 0.5em;    
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    width: 1em;
    height: 1em;
    @media (max-width: 1199px) {
      left: 2em;
    }
    @media (max-width: 990px) {
      left: 1em;
    }
    @media (max-width: 575px) {
      left: 0em;
    }    
 `;

const Body = styled.div`  
    margin: 0;
    padding: 0;
    padding-top: 2em;
    padding-left: 2em;    
    position: relative;
    top: -1.875em;
    h3 {
      font-size: 1.75em;
    }
    h4 {
      font-size: 1.2em;
      margin-bottom: 1.2em;
    }
    @media (max-width: 1199px) {
      left: 4em;
      padding-left: 1.8em;
    }
    @media (max-width: 990px) {
      left: 1em;
      padding-left: 1.5em;
    }
    @media (max-width: 575px) {
      left: 0em;
      padding-left: 1.2em;
    }       
`;

const Date = styled.p`  
    margin: 0;
    padding: 0;
    color: #43414E;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 8px;
    padding: 0.2em 0em 0.2em 0em;
`;

const Description = styled.div`  
    strong {
      font-weight: 700;
    }
`;



const Event = ({ active, title, subtitle, interval, children }) =>
  <EventContainer>
    {active ? 
      (<IconActive></IconActive>) : 
      (<Icon></Icon>) }
    
    <Body>
      <Date>{interval}</Date>
      {subtitle && <h4>{subtitle}</h4>}
      <Description>
        {children}
      </Description>
    </Body>
  </EventContainer>

export default Event;