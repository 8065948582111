import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.05em;
    * {
      box-sizing: border-box;
      border: 0;
      vertical-align: top;
    }
`;


const TimelineInActive = styled.div`
    position: relative;
    max-width: 95%;
    list-style: none;
    &:before {
      background-color: #E3E1Ee;
      content: '';
      margin-left: -1px;
      position: absolute;
      top: 0;
      width: 2px;
      height: 100%;
    }
    @media (max-width: 1199px) {
      &:before {
        left: 2em;
      }
    }
    @media (max-width: 990px) {
      &:before {
        left: 1em;
      }
    }
    @media (max-width: 575px) {
      &:before {
        left: 0em;
      }
    }
`;

const TimelineActive = styled.div`  
    position: relative;
    max-width: 95%;
    list-style: none;
    &:before {
      background-color: #b3b9e7;
      content: '';
      margin-left: -1px;
      position: absolute;
      top: 0;
      width: 2px;
      height: 100%;
    }
    @media (max-width: 1199px) {
      &:before {
        left: 2em;
      }
    }
    @media (max-width: 990px) {
      &:before {
        left: 1em;
      }
    }
    @media (max-width: 575px) {
      &:before {
        left: 0em;
      }
    }    
`;

const Timeline = ({ active, children }) =>
  <Container>
    {active ? 
      (<TimelineActive>{children}</TimelineActive>) :
      (<TimelineInActive>{children}</TimelineInActive>) }
  </Container>

export default Timeline;